import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../app_provider";
import Card from "../../common/components/other/card";
import { useNavigate } from "react-router-dom";
import './thanks.css';
import { fetchDealFieldsFromApp, flushLocal, readDealId } from "../../utilities/methods";
import { sendEmail, updateADeal } from "../../utilities/api";
import { getJointApplicantInfo, isJointApplication } from "../../utilities/local";
import { useRef } from "react";
const Thanks = () => {
    const {updateLoading,setProgress} = useContext(AppContext);
    const mailStatus = useRef(false); 
    const navigate = useNavigate();
    useEffect(()=>{
        updateLoading(false);
        setProgress(100);
        fetchIsCompleted();
    },[]);
    const fetchIsCompleted =async ()=>{
      let jointApplication = isJointApplication();
      if(!jointApplication){
        let host = window.location.href.split("/thanks")[0];
        let dealId = readDealId();
        const {Joint_Applicant_Name, Joint_Applicant_Email_Address} = getJointApplicantInfo();
        const payload ={
            "toAddress":Joint_Applicant_Email_Address,
            "baseUrl":host,
            "dealId":dealId,
            "applicantName":Joint_Applicant_Name,
        }
        console.log(payload);
        try {
            let response = await sendEmail(payload);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
      }
    }
    return (<>
    <div className="thanks-root">
        <Card>
            <div className="thanks-title">Thank you for your application</div>
            <div className="thanks-body">
            One of the BYD team will be in touch in the next hour.
            </div>
<button className="button primaryBackground home" onClick={async()=>{
    await fetchIsCompleted();
    navigate("/");
}}>Home</button>
        </Card>
    </div>
    </>);
};
export default Thanks;
