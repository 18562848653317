import { URL_KEY } from "../common/assets/const";

export const getBaseUrl = ()=>{
    let clientSource = localStorage.getItem(URL_KEY);
    clientSource = JSON.parse(clientSource);
    return clientSource[0];
}
export const setBaseUrl = (baseUrl)=>{
    let clientSource = localStorage.getItem(URL_KEY);
    if(!clientSource){
        localStorage.setItem(URL_KEY,JSON.stringify([baseUrl]));
        return;
    }
    clientSource = JSON.parse(clientSource);
    clientSource[0] = baseUrl;
    clientSource = JSON.stringify(clientSource);
    localStorage.setItem(URL_KEY,clientSource);
};
export const getAccessToken = ()=>{
    let clientSource = localStorage.getItem(URL_KEY);
    clientSource = JSON.parse(clientSource);
    return clientSource[1];
}
export const setAccessToken = (accessToken)=>{
    let clientSource = localStorage.getItem(URL_KEY);
    clientSource = JSON.parse(clientSource);
    clientSource[1] = accessToken;
    clientSource = JSON.stringify(clientSource);
    localStorage.setItem(URL_KEY,clientSource);
};
export const getRefreshToken = ()=>{
    let clientSource = localStorage.getItem(URL_KEY);
    clientSource = JSON.parse(clientSource);
    return clientSource[2];
}
export const setRefreshToken = (accessToken)=>{
    let clientSource = localStorage.getItem(URL_KEY);
    clientSource = JSON.parse(clientSource);
    clientSource[2] = accessToken;
    clientSource = JSON.stringify(clientSource);
    localStorage.setItem(URL_KEY,clientSource);
};
export const setIsJointApplication = ()=>{
    localStorage.setItem("JOINT_APPLICAION",'TRUE');
}
export const isJointApplication = ()=>localStorage.getItem("JOINT_APPLICATION")==="TRUE";
export const saveJointApplicantInfo = (payload)=>{
    const stringified = JSON.stringify(payload);
    localStorage.setItem("JOINT_APPLICANT_INFO",stringified);
}
export const getJointApplicantInfo = ()=>JSON.parse(localStorage.getItem("JOINT_APPLICANT_INFO"));