import axios from "axios";
import { AppContext } from "../../app_provider";
import React, { useState , useContext} from "react";
import { PipedriveApi, PipedriveAuthToken } from "../../utilities/api";
import { pipedriveKey, readDealId, readPRStatus } from "../../utilities/methods";
import ButtonPanel from "../../common/components/other/button_panel";
import "./file_upload.css";
import Card from "../../common/components/other/card";
import Licence from "../../common/assets/images/licence-face.png";
import Passport from "../../common/assets/images/passport.png";
import Visa from "../../common/assets/images/visa.png";
import Check from '../../common/assets/images/check.png';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const FileUploads = () => {
  const { updateLoading, isLoading, setProgress,openAlert } = useContext(AppContext);
  const [drivingLicenceOne, setDrivingLicenceOne] = useState();
  const [drivingLicenceTwo, setDrivingLicenceTwo] = useState();
  const [passport, setPassport] = useState();
  const [visa, setVisa] = useState();
  const [isPermanantResident,setPermanantResidencyStatus] = useState(false);
  const [onWorkVisa, setWorkVisaStatus] = useState(false);
  const navigate  = useNavigate();
  let {dealId} = useParams();
  const uploadFiles = async (file) => {
    let form = new FormData();
    if(!dealId){
      dealId = readDealId();
    }
    form.append("file", file);
    form.append("deal_id", dealId);
    await upload(form);
  };
  useEffect(()=>{
    setProgress(60);
    updateLoading(false);
    let isPrHolder = readPRStatus();
    setPermanantResidencyStatus(isPrHolder);
    return ()=>{};
  },[]);
  const upload = async (formData) => {
    let response = await axios.post(
      `${PipedriveApi}/files?api_token=${PipedriveAuthToken}`,
      formData
    );
    
  };
  return (
    <>
      <div className="master-wrap">
        <Card className="card-wrap">
          <div className="upload-title">Uploading documents</div>
          <div className="sub-title">
            - Upload a copy of your driver's license
          </div>

          <div className="box-wrapper">
            <div className="row">
              <div className="focus">
                <div className="sub-title">- Front Side</div>
                <div class="card-black">
                  <div className="container">
                    {drivingLicenceOne===undefined?<img src={Licence} />:<img src={Check}/>}
                    
                  </div>
                </div>
                <div className="buttons">
                  <label for="fileOne" className="upload">
                    Upload
                  </label>
                  <label className="grey remove" onClick={()=>{
                    setDrivingLicenceOne(undefined);
                  }}>Remove</label>
                </div>
              </div>
              <div className="focus">
                <div className="sub-title">- Back Side</div>
                <div class="card-black">
                  <div className="container">
                  {drivingLicenceTwo===undefined?<img src={Licence} />:<img src={Check}/>}
                  </div>
                </div>
                <div className="buttons">
                  <label for="fileTwo" className="upload">
                    Upload
                  </label>
                  <label className="grey remove" onClick={()=>{
                    setDrivingLicenceTwo(undefined);
                  }}>Remove</label>
                </div>
              </div>
            </div>
            <div className={`row-simple ${isPermanantResident?`visibility`:``}`}>
              <input
                type="checkbox"
                value={onWorkVisa}
                checked={onWorkVisa}
                className={`${isPermanantResident?`visibility`:``}`}
                onChange={() => {
                  setWorkVisaStatus(!onWorkVisa);
                }}
              />
              <div className={`sub-title ${isPermanantResident?`visibility`:``}`}>Are you on a work visa? </div>
            </div>
            <div className={`row ${onWorkVisa && !isPermanantResident ? `` : `visibility`}`}>
              <div className="focus">
                <div className="sub-title">- Work Visa</div>
                <div class="card-black">
                  <div className="container">
                    {visa===undefined?<img src={Visa} />:<img src={Check}/>}
                  </div>
                </div>
                <div className="buttons">
                  <label for="fileFour" className="upload">
                    Upload
                  </label>
                  <label className="grey remove" onClick={()=>{
                    setVisa(undefined);
                  }}>Remove</label>
                </div>
              </div>
              <div className="focus">
                <div className="sub-title">- Passport</div>
                <div class="card-black">
                  <div className="container">
                    {passport===undefined?<img src={Passport} />:<img src={Check}/>}
                  </div>
                </div>
                <div className="buttons">
                  <label for="fileThree" className="upload">
                    Upload
                  </label>
                  <label className="grey remove" onClick={()=>{
                    setPassport(undefined);
                  }}>Remove</label>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <input
        id="fileOne"
        className="file"
        type="file"
        onChange={(e) => {
          setDrivingLicenceOne(e.target.files[0]);
        }}
      />
      <input
        type="file"
        id="fileTwo"
        className="file"
        onChange={(e) => {
          setDrivingLicenceTwo(e.target.files[0]);
        }}
      />
      <input
        type="file"
        id="fileThree"
        className="file"
        onChange={(e) => {
          setPassport(e.target.files[0]);
        }}
      />
      <input
        type="file"
        id="fileFour"
        className="file"
        onChange={(e) => {
          setVisa(e.target.files[0]);
        }}
      />
      <ButtonPanel
        isPreviousEnabled={false}
        forwardMethod={async () => {
          updateLoading(true);
         if(drivingLicenceOne!==undefined&&drivingLicenceOne!==null){
          await uploadFiles(drivingLicenceOne);
         }
         else{
          openAlert('Upload driving licence front.','Driving Licence missing.');
          return;
         }
         if(drivingLicenceTwo!==undefined&&drivingLicenceTwo!==null){
          await uploadFiles(drivingLicenceTwo);
         }
         else{
          openAlert('Upload driving licence back.','Driving Licence missing.');
          return;
         }
         if(visa!==undefined&&visa!==null){
          await uploadFiles(visa);
         }
         if(passport!==undefined&&passport!==null){
          await uploadFiles(passport);
         }
         updateLoading(false);
          navigate("/illion");
        }}
      />
    </>
  );
};
export default FileUploads;
