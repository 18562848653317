import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../app_provider";
import ButtonPanel from "../../common/components/other/button_panel";
import Card from "../../common/components/other/card";
import { saveJointApplicantInfo } from "../../utilities/local";
import "./index.css";

const ApplicationMode = () => {
  const { setProgress } = useContext(AppContext);
  const [isJointApplication, setJointApplication] = useState("");
  const [formData,setFormData] = useState({});
  const [formError,setFormError] = useState({});
  const updateApplicationType = (type) => {
    setJointApplication(type);
  };
  useEffect(() => {
    setProgress(13);
  }, []);
  useEffect(()=>{
    console.log(formData);
  },[formData]);
  const navigate = useNavigate();
  const onChangeinput = ({target})=>{
    const {name,value} = target;
    setFormData({...formData,[name]:value});
  }
  const onSubmit = ()=>{
    let error = {};
   if(isJointApplication==='yes'){
    if(!formData["Joint_Applicant_Email_Address"]){
      error= {...error,Joint_Applicant_Email_Address:true};
    }
    if(!formData["Joint_Applicant_Name"]){
      error = {...error,Joint_Applicant_Name:true};
    }
   }
    if(Object.keys(error).length>0){
      console.log(error);
      setFormError(error);
      return;
    }
    saveJointApplicantInfo(formData);
    navigate("/personalInfo");
  }
  return (
    <>
      <div className="applicant-type-root">
        <Card>
          <div className="applicant-type-wrap">
            <div className="question">Is it a Joint Application?</div>
            <div className="options">
              <div
                onClick={() => {
                  updateApplicationType("yes");
                }}
                className={`smallPadding ${
                  isJointApplication === "yes"
                    ? `primaryBackground`
                    : `white-border`
                }`}
              >
                <div className="option-inner">Yes</div>
              </div>
              <div
                onClick={() => {
                  updateApplicationType("no");
                }}
                className={`smallPadding ${
                  isJointApplication === "no"
                    ? `primaryBackground`
                    : `white-border`
                }`}
              >
                <div className="option-inner">No</div>
              </div>
            </div>
            <div className={`input_container ${
                  isJointApplication === "yes"
                    ?``:`hidden`}`}>
              <label>Enter the other applicant email and name</label>
              <div>
                <label>Email Address</label>
                <input placeholder="Email Address" className={formError["Joint_Applicant_Email_Address"]?`error`:``} onChange={onChangeinput} name="Joint_Applicant_Email_Address"/>
              </div>
              <div>
                <label>Name</label>
                <input placeholder="Name" className={formError["Joint_Applicant_Name"]?`error`:``} onChange={onChangeinput} name="Joint_Applicant_Name"/>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <ButtonPanel
        isPreviousEnabled={false}
        forwardMethod={() => {
          onSubmit();
        }}
      />
    </>
  );
};
export default ApplicationMode;
