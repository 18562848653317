import React from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { AppContext } from "../../app_provider";
import Card from "../../common/components/other/card";
import { extractAPIKey, fetchDealFieldsFromApp, readMortageStatus } from "../../utilities/methods";
import ButtonPanel from "../../common/components/other/button_panel";
import "./liabilities_expense.css";
import { saveLiabilityExpense, updateADeal } from "../../utilities/api";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { buyNowPayLater, buyNowPayLaterFields, carLoan, carLoanFields, creditCardsNoField, expenceFields, mortageFields, personalLoan, personalLoanFields } from "./constants";
import { fetchLabelText } from "./methods";
const JointApplicantLiabilitiesExpence = () => {
  /// States

  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [creditCardFields, setCreditCardFields] = useState([]);
  const [isMortaged,setIsMortaged] = useState(false);
  const { setProgress, updateLoading, isMarried,showToast } = useContext(AppContext);
  const navigate = useNavigate();
  const { dealId } = useParams();

  /// UseEffect
  useEffect(() => {
    setProgress(60);
    let formFieldsFromLocal = fetchDealFieldsFromApp();
    setFormFields(formFieldsFromLocal);
    let _isMortgaged = readMortageStatus();
    setIsMortaged(_isMortgaged);
    updateLoading(false);
  }, []);
  useEffect(() => {
    let noOfCards = formData[extractAPIKey("Joint_Applicant_No_Of_Credit_Cards")];
    if (noOfCards > 0) {
      let cardFields = [];
      for (let index = 1; index <= noOfCards; index++) {
        cardFields.push(`Joint_Applicant_Credit_Card_Provider_${index}`);
        cardFields.push(`Joint_Applicant_Credit_Card_Limit_${index}`);
      }
      console.log(cardFields);
      setCreditCardFields(cardFields);
    } else {
      setCreditCardFields([]);
    }
    console.log(formData);
  }, [formData]);


  const onInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setFormErrors({ ...formErrors, [name]: false });
    setFormData({
      ...formData,
      [name]: !isNaN(value.split(",").join(""))
        ? numberWithCommas(value.split(",").join(""))
        : value,
    });
  };
  function numberWithCommas(x) {
    if (x < 0 || x === "" || x === undefined || x === null) {
      return;
    }
    let number = parseInt(x);
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
  }

  const onSubmit = async () => {
    let errors = {};
    formFields.forEach((field) => {
      if (
        expenceFields.includes(field.name) &&
        (formData[field.key] === null ||
          formData[field.key] === undefined ||
          formData[field.key] === "")
      ) {
        errors = { ...errors, [field.key]: true };
      }
    });
    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    //   console.log(errors);
    //   return;
    // }
    try {
      let response = await saveLiabilityExpense(formData,dealId);
      showToast("Expense and liabiility information saved.");
      navigate(`/fileUploads/${dealId}`);

    } catch (error) {
      console.log(error);
    }
    // updateLoading(true);
    // await updateADeal(formData);
    // updateLoading(false);
    
  };
  const onChangeRadio = (key, value, name) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <>
      <div className="in__ex__container">
        <Card>
          <div className="in__ex__card">
            <div className="title">Great work you are almost finished </div>
            <div className="expense__wrap">
              <div className="income__header">Expenses</div>
              <div className="expense__fields__wrap">
                {formFields.map((entry) => {
                  return expenceFields.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(entry.name)}</div>
                      <label data-domain="$">
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={formData[entry.key] || 0}
                        />
                      </label>
                      <div className="expense__freequancy">
                        Freequancy <span>Monthly</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
            <div className="expense__wrap">
              <div className="income__header">Liabilities</div>
              <div className={`expense__fields__wrap ${isMortaged?``:`hide`}`}>
                {formFields.map((entry) => {
                  return mortageFields.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(entry.name)}</div>
                      <label
                        data-domain={
                          entry.name === "Joint_Applicant_Mortgage_Provider" ? `` : `$`
                        }
                      >
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={
                            entry.name === "Joint_Applicant_Mortgage_Provider"
                              ? formData[entry.key]
                              : formData[entry.key] || 0
                          }
                        />
                      </label>
                      <div className="expense__freequancy"></div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="expense__fields__wrap ">
                {formFields.map((entry) => {
                  return creditCardsNoField.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row select-wrap">
                      <div>{fetchLabelText(entry.name)}</div>
                      <div className="credit_card_no_wrapper">
                        <select
                          className={`select__credit__card ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={formData[entry.key]}
                        >
                          <option value={null}>Select Cards</option>
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                      <div className="expense__freequancy"></div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="expense__fields__wrap">
                {creditCardFields.map((entry) => {
                  let field = formFields.filter(
                    (field) => field.name === entry
                  )[0];
                  console.log(field);
                  return (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(field.name,creditCardFields)}</div>
                      <label
                        data-domain={
                          field.name.includes("Joint_Applicant_Credit_Card_Limit") ? `$` : ``
                        }
                      >
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[field.key] ? `error` : ``
                          }`}
                          name={field.key}
                          onChange={onInputChange}
                          value={
                            field.name.includes("Joint_Applicant_Credit_Card_Limit")
                              ? formData[field.key] || 0
                              : formData[field.key]
                          }
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="income__fields__wrap bottom__margin__lg">
                {formFields.map((entry) => {
                  return buyNowPayLater.includes(entry.name) ? (
                    <div className="expense__input__container radio__wrapper margin__top">
                      <div className="radio_label">
                        Do you have any Buy now pay later ?
                      </div>
                      <div className="radio_select">
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio"
                            className="checkbox-custom"
                            type={"radio"}
                            value="Yes"
                            onClick={() => {
                              onChangeRadio(entry.key, "Yes", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio"
                            className="range__label checkbox-custom-label"
                          >
                            <div>Yes</div>
                          </label>
                        </div>
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio-2"
                            type={"radio"}
                            value="No"
                            className="checkbox-custom"
                            onClick={() => {
                              onChangeRadio(entry.key, "No", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio-2"
                            className="range__label checkbox-custom-label"
                          >
                            <div>No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div
                className={`expense__fields__wrap ${
                  formData[extractAPIKey("Joint_Applicant_Have_Buy_Now_Pay_Later")] === "Yes"
                    ? ``
                    : `hide`
                }`}
              >
                {formFields.map((entry) => {
                  return buyNowPayLaterFields.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(entry.name)}</div>
                      <label
                        data-domain={
                          entry.name === "Joint_Applicant_Buy_Now_Pay_Later_Owing" ? `$` : ``
                        }
                      >
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={formData[entry.key]}
                        />
                      </label>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="income__fields__wrap bottom__margin__lg">
                {formFields.map((entry) => {
                  return personalLoan.includes(entry.name) ? (
                    <div className="expense__input__container radio__wrapper">
                      <div className="radio_label">
                        Do you have any personal loans?
                      </div>
                      <div className="radio_select">
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio3"
                            className="checkbox-custom"
                            type={"radio"}
                            value="Yes"
                            onClick={() => {
                              onChangeRadio(entry.key, "Yes", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio3"
                            className="range__label checkbox-custom-label"
                          >
                            <div>Yes</div>
                          </label>
                        </div>
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio-4"
                            type={"radio"}
                            value="No"
                            className="checkbox-custom"
                            onClick={() => {
                              onChangeRadio(entry.key, "No", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio-4"
                            className="range__label checkbox-custom-label"
                          >
                            <div>No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div
                className={`expense__fields__wrap ${
                  formData[extractAPIKey("Joint_Applicant_Have_Personal_Loan")] === "Yes"
                    ? ``
                    : `hide`
                }`}
              >
                {formFields.map((entry) => {
                  return personalLoanFields.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(entry.name)}</div>
                      <label
                        data-domain={
                          entry.name === "Joint_Applicant_Personal_Loan_Balance" ||
                          entry.name === "Joint_Applicant_Personal_Loan_Repayment_Amount"
                            ? `$`
                            : ``
                        }
                      >
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={
                            entry.name === "Joint_Applicant_Personal_Loan_Balance" ||
                            entry.name === "Joint_Applicant_Personal_Loan_Repayment_Amount"
                              ? formData[entry.key] || 0
                              : formData[entry.key]
                          }
                        />
                      </label>
                      <div className="expense__freequancy">
                        {entry.name === "Joint_Applicant_Personal_Loan_Repayment_Amount"
                          ? `Frequency`
                          : ``}
                        <span>
                          {" "}
                          {entry.name === "Joint_Applicant_Personal_Loan_Repayment_Amount"
                            ? `Monthly`
                            : ``}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="income__fields__wrap bottom__margin__lg">
                {formFields.map((entry) => {
                  return carLoan.includes(entry.name) ? (
                    <div className="expense__input__container radio__wrapper">
                      <div className="radio_label">
                        Do you have any car loans?
                      </div>
                      <div className="radio_select">
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio5"
                            className="checkbox-custom"
                            type={"radio"}
                            value="Yes"
                            onClick={() => {
                              onChangeRadio(entry.key, "Yes", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio5"
                            className="range__label checkbox-custom-label"
                          >
                            <div>Yes</div>
                          </label>
                        </div>
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio-6"
                            type={"radio"}
                            value="No"
                            className="checkbox-custom"
                            onClick={() => {
                              onChangeRadio(entry.key, "No", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio-6"
                            className="range__label checkbox-custom-label"
                          >
                            <div>No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div
                className={`expense__fields__wrap ${
                  formData[extractAPIKey("Joint_Applicant_Have_Car_Loan")] === "Yes"
                    ? ``
                    : `hide`
                }`}
              >
                {formFields.map((entry) => {
                  return carLoanFields.includes(entry.name) ? (
                    <div className="expense__input__container input-wrap-row">
                      <div>{fetchLabelText(entry.name)}</div>
                      <label
                        data-domain={
                          entry.name === "Joint_Applicant_Car_Loan_Balance" ||
                          entry.name === "Joint_Applicant_Car_Loan_Repayment_Amount"
                            ? `$`
                            : ``
                        }
                      >
                        <input
                          autoComplete="off"
                          className={`income__input ${
                            formErrors[entry.key] ? `error` : ``
                          }`}
                          name={entry.key}
                          onChange={onInputChange}
                          value={
                            entry.name === "Joint_Applicant_Car_Loan_Balance" ||
                            entry.name === "Joint_Applicant_Car_Loan_Repayment_Amount"
                              ? formData[entry.key] || 0
                              : formData[entry.key]
                          }
                        />
                      </label>
                      <div className="expense__freequancy">
                        {entry.name === "Joint_Applicant_Car_Loan_Repayment_Amount"
                          ? `Frequency`
                          : ``}
                        <span>
                          {" "}
                          {entry.name === "Joint_Applicant_Car_Loan_Repayment_Amount"
                            ? `Monthly`
                            : ``}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
        <div className="button__container">
          <ButtonPanel
            isPreviousEnabled={false}
            forwardMethod={async () => {
              await onSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};
export default JointApplicantLiabilitiesExpence;
