import React, { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import secret from "./utilities/config.json";
import { URL_KEY } from "./common/assets/const";
import { setBaseUrl } from "./utilities/local";
import { fetchDealFieldsFromApp } from "./utilities/methods";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const AppContext = createContext({
  /// Make [true] for Loading
  isLoading: true,
  /// Define the title and content of alert
  alertContent: "",
  alertTitle: "",
  /// make true for open alert
  showAlert: false,
  /// Payload to send to pipedrive
  payloadToSend: {},
  /// progress
  progress: 0,
  /// Method to update loaidng status
  dealFields: [],

  app: null,
  config: {},
  isMarried: false,
  setIsMarried: () => {},
  updateLoading: () => {},
  /// Method to show alert
  openAlert: () => {},
  /// Method to close alert
  closeAlert: () => {},
  /// Method to update the payload to pipedrive
  updatePayload: () => {},
  /// Method to update progress
  setProgress: () => {},
  /// Method to set deal fields
  updateDealFields: () => {},
  showToast: () => {},
});
const AppProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [alertContent, setAlertContent] = useState();
  const [alertTitle, setAlertTitle] = useState();
  const [payloadToSend, setPayloadToSend] = useState({});
  const [showAlert, setAlertStatus] = useState(false);
  const [progress, updateProgress] = useState(0);
  const [app, setApp] = useState();
  const [config, setConfig] = useState();
  const [isMarried, setIsMarried] = useState(false);
  const updateLoading = (loadingStatus) => setLoading(loadingStatus);
  const openAlert = (alertContent, alertTitle) => {
    setAlertContent(alertContent);
    setAlertTitle(alertTitle);
    setAlertStatus(true);
  };
  const setProgress = (progress) => {
    updateProgress(progress);
  };
  const closeAlert = () => setAlertStatus(false);
  const updatePayload = (jsonData) => {
    //  let apiKey= getKey(name);
    //
    //  let _payload = {...paloadToSend,[apiKey]:value};
    //
    //   setPayloadToSend(_payload);
    setPayloadToSend({ ...payloadToSend, ...jsonData });
  };
  useEffect(() => {
    setLoading(true);
    // let firebaseApp = initializeApp(secret);
    //setApp(firebaseApp);
    //let remoteConfig = getRemoteConfig(firebaseApp);
    //setConfig(remoteConfig);
    let dealFields = fetchDealFieldsFromApp();
    if (dealFields) {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    remoteConfigActivate(config);
  }, [config]);
  const remoteConfigActivate = async (config) => {
    // console.log(config);
    // let isFetched = await fetchAndActivate(config);
    // let base_url = getValue(config, "base_url");
    // const url = `${window.location.protocol}//${window.location.host}`;
    // console.log(url)
    const url = `https://1jsqd1pbcl.execute-api.ap-northeast-1.amazonaws.com/api`;
    setBaseUrl(url);
  };
  const showToast = (message) => {
    toast.info(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const values = {
    isLoading,
    alertContent,
    alertTitle,
    payloadToSend,
    showAlert,
    progress,
    app,
    config,
    isMarried,
    setIsMarried,
    closeAlert,
    updateLoading,
    openAlert,
    updatePayload,
    setProgress,
    showToast,
  };
  return (
    <AppContext.Provider value={values}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
