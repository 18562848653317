import React, { useContext, useEffect, useState } from "react";
import Card from "../../common/components/other/card";
import { useNavigate } from "react-router-dom";
import Employment from "../../common/assets/images/employment.svg";
import Income from "../../common/assets/images/income.svg";
import Licence from "../../common/assets/images/licence.svg";
import "./pre_requisties.css";
import { AppContext } from "../../app_provider";

const ApplicationPrerquisties = () => {
  const { openAlert, updateLoading } = useContext(AppContext);
  const [acceptedTerms, setAcceptedterms] = useState(false);
  const history = useNavigate();
  const onChangeAcceptStatus = () => {
    setAcceptedterms(!acceptedTerms);
  };
  const unableToStart = () => {
    openAlert(
      "You can't start applying without accepting terms.",
      "Accept Terms"
    );
  };
  const startApplication = () => {
    history("/applicationMode");
  };
  useEffect(() => {
    updateLoading(false);
  }, []);
  return (
    <>
      <div className="main-wrapper pre-request-root">
        <Card>
          <div className="card-inner">
            <div className="title">Before you apply, We will need:</div>
            <div className="card-row">
              <div className="card-row-wrapper">
              <div className="blackBackground border-radius-small">
                <div className="card-inner-box">
                  <img className="image" src={Licence} alt="Licence Icon" />
                  <div className="font">
                    Your Current New Zealand full or restricted Driver licence
                  </div>
                </div>
              </div>
              <div className="blackBackground border-radius-small">
                <div className="card-inner-box">
                  <img
                    className="image-duplicate"
                    src={Employment}
                    alt="Employment Icon"
                  />
                  <div className="font">Your employments details</div>
                </div>
              </div>
              <div className="blackBackground border-radius-small">
                <div className="card-inner-box">
                  <img
                    className="image-duplicate"
                    src={Income}
                    alt="Income Icon"
                  />
                  <div className="font">
                    Details of your income, expenses, assets & liabilities
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="proceed-condition">
              <div className="line"></div>
              <div className="proceed-text font">
                In order to proceed with this application
              </div>
              <div className="line"></div>
            </div>
            <div className="declaration-wrap">
            <div className="declaration-wrapper">
              <div className="blackBackground border-radius-small">
                <div className="declaration-container font">
                  Whether you are a New Zealand resident or not we can help you.
                </div>
              </div>
              <div className="blackBackground border-radius-small">
                <div className="declaration-container font">
                  The loan amount you are applying for must be at least $10,000
                </div>
              </div>
              </div>
            </div>
            <div className="line updated"></div>
            <div className="declaration-accept-wrap">
              <div className="declaration-accept-check">
              <input id="checkbox-tick" class="checkbox-custom" name="checkbox-3" type="checkbox" onChange={onChangeAcceptStatus}
/>
                      <label for="checkbox-tick" class="checkbox-custom-label">By ticking this box, you confirm that:</label>
              </div>
            </div>
            <div className="declaration-list-wrap font">
              <ul>
                <li>You meet each of the requirements sent out above</li>
                <li>
                  You have read BYD Auckland <a href="/"> Privacy Statement</a>{" "}
                  and you understand that it applies to your loan application
                </li>
                <li>
                  You understand that lending criteria applies to this loan
                  application
                </li>
              </ul>
            </div>
          </div>
        </Card>
        <button
          className={`red-button button ${
            acceptedTerms ? `primaryBackground` : `greyBackground`
          }  start`}
          onClick={acceptedTerms ? startApplication : unableToStart}
        >
          START APPLICATION&nbsp;&nbsp;<strong>&#8594;</strong>
        </button>
      </div>
    </>
  );
};
export default ApplicationPrerquisties;
