import React from 'react';
import './others.css';

const Card = ({children})=>{
    return (
        <div className='card'>
            {children}
        </div>
    );
};
export default Card;