import React from "react";
import "./footer.css";
import FooterLogo from "../../assets/images/powerd.png";
const Footer = () => {
  return (
    <div className="rootFooter greyBackgroundFooter">
      <div className="footerContent">
        <div className="grey">Powered by</div>
        <img className="logo" src={FooterLogo} />
      </div>
    </div>
  );
};
export default Footer;
