import React from "react";
import "../../css/alert.css";
import Loader from 'react-dots-loader'
import './spinner.css';
import FooterLogo from "../../assets/images/logo.png";

const Loading = (props) => {
  const { showLoading } = props;
  return showLoading ? (
    <section className="loading_section">
    <div>
      <img src={FooterLogo} alt="Logo" width="128px" />
    </div>
    <div className="loader"></div>
    <div color="#FFF" className="loading_text">
      Loading...
    </div>
  </section>
  ) : null;
};
export default Loading;
