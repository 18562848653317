import React, { useState, useEffect, useContext } from "react";
import Card from "../../common/components/other/card";
import TextField from "../../common/components/other/text_field";
import "./landing.css";
import {
  createDeal,
  downloadPipedriveFields,
  getDealFields,
} from "../../utilities/api";
import { AppContext } from "../../app_provider";
import { useNavigate } from "react-router-dom";
import {
  downloadFieldsPipedrive,
  fetchDealFieldsFromApp,
  flushData,
  flushLocal,
  saveDealFieldsToApp,
  saveFormFields,
  storeDealId,
} from "../../utilities/methods";
import Atto from "../../common/assets/images/atto.png";

const Landing = () => {
  const { updateLoading, openAlert, setProgress,showToast } = useContext(AppContext);
  const [residualOrBaloonPayment, setResidualOrBaloonPayment] = useState(false);
  const [keepRebate, setKeepRebate] = useState(false);
  const [delayedDeposit, setDelayedDeposit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [retryCount, setRetryCount] = useState();
  const [isStandardRange, setStandardRange] = useState(true);
  const [isResidualVisible, setResidualVisible] = useState(false);
  const navigate = useNavigate();
  const validation = ["Borrow_Duration", "Borrow_Deposit"];
  const onChangeInput = (e) => {
    setResidualOrBaloonPayment(!residualOrBaloonPayment);
    setFormValues({
      ...formValues,
      [e.target.name]: !residualOrBaloonPayment ? `Yes` : `No`,
    });
  };
  useEffect(() => {
    flushData();
    setProgress(0);
    fetchFields();

    setFormValues({
      ...formValues,
      Range_Type: `Standard Range - $55,490 +ORC`,
    });
  }, []);
  useEffect(() => {
    if (formValues["Borrow_Duration"] <= 5) {
      setResidualVisible(true);
    } else {
      setResidualVisible(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (retryCount < 8) {
      setTimeout(() => {
        downloadDealFields();
      }, 5000);
    }
    if (retryCount === 8) {
      updateLoading(false);
      openAlert("Unable to connect to internet", "Error");
    }
  }, [retryCount]);
  const fetchFields = async () => {
    let dealFieldsLocal = fetchDealFieldsFromApp();
    downloadDealFields();
    updateLoading(false);
    if (retryCount > 8 && retryCount !== undefined) {
      updateLoading(false);
    }
  };
  const handleInputChange = (e) => {
    flushLocal();
    fetchFields();
    const { name, value } = e.target;
    if (errorFields[name]) {
      delete errorFields[name];
    }

    setFormValues({
      ...formValues,
      [name]: numberWithCommas(value),
    });
  };
  function numberWithCommas(x) {
    if (x < 0 || x === "" || x === undefined || x === null) {
      return;
    }
    let number = x.toString().replace(",", "");
    number = parseInt(number);
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
  }
  const downloadDealFields = async () => {
    updateLoading(true);
    try {
      let _dealFields = await downloadFieldsPipedrive();
      saveDealFieldsToApp(_dealFields);
      updateLoading(false);
    } catch (error) {
      if (retryCount === undefined) {
        setRetryCount(1);
        return;
      }
      setRetryCount(retryCount + 1);
    }
  };
  const handleSubmit = async () => {
    updateLoading(true);
    let error = {};
    validation.forEach((entry) => {
      if (
        formValues[entry] === undefined ||
        formValues[entry] === "" ||
        formValues[entry] === null
      ) {
        error = { ...error, [entry]: true };
      }
    });
    setErrorFields(error);
    if (Object.keys(error).length > 0) {
      setErrorFields(error);
      updateLoading(false);
      return;
    }
    let response = await createDeal({
      ...formValues,
      title: constructRandomString(16),
      Tool_Name: "BYD FINANCE"
    });
    const { dealId } = response;
    storeDealId(dealId);
    updateLoading(false);
    showToast("Deal created successfully!");
    navigate("/applicationType");
  };
  const constructRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return (
    <>
      <div className="landingRoot inner-container home-inner-container">
        <Card>
          <div className="card__inner">
            <div className="header main-title">
              <h2>Welcome lets get you a new car!</h2>
            </div>
            <div>
              <p>
                {" "}
                I would like to buy an <img src={Atto} />{" "}
              </p>
            </div>
            <div className="form ">
              <div className="checkbox-wrapper">
                <div className="flex-col">
                  <div className="item">
                    <input
                      id="radio-1"
                      type={"radio"}
                      className="checkbox-custom"
                      name="Range_Type"
                      checked={isStandardRange}
                      onClick={(e) => {
                        setStandardRange(!isStandardRange);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: `Standard Range - $55,490 +ORC`,
                        });
                      }}
                    />
                    <label
                      for="radio-1"
                      className="range__label checkbox-custom-label"
                    >
                      Standard Range - $55,490 +ORC
                    </label>
                  </div>
                  <div className="item">
                    <input
                      id="radio-2"
                      className="checkbox-custom"
                      type={"radio"}
                      name="Range_Type"
                      checked={!isStandardRange}
                      onClick={(e) => {
                        setStandardRange(!isStandardRange);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: `Extend Range - $59,990 +ORC`,
                        });
                      }}
                    />
                    <label
                      for="radio-2"
                      className="range__label checkbox-custom-label"
                    >
                      Extend Range - $59,990 +ORC
                    </label>
                  </div>
                </div>
              </div>
              <div className="first-line">
                <div className="flex-row">
                  <div className="first-line-item">
                    <p>Loan term</p>
                  </div>
                  <div className="first-line-item">
                    <div className="input_select">
                      <select
                        className={`${
                          formValues["Borrow_Duration"] === undefined ||
                          formValues["Borrow_Duration"] === null
                            ? `hint-select`
                            : ``
                        }${errorFields[`Borrow_Duration`] ? `error` : ``}`}
                        onChange={handleInputChange}
                        name="Borrow_Duration"
                        value={formValues["Borrow_Duration"]}
                      >
                        <option value={undefined}>Years</option>
                        <option value={1}>1 Years</option>
                        <option value={2}>2 Years</option>
                        <option value={3}>3 Years</option>
                        <option value={4}>4 Years</option>
                        <option value={5}>5 Years</option>
                        <option value={6}>6 Years</option>
                        <option value={7}>7 Years</option>
                      </select>
                    </div>
                  </div>
                  <div className="first-line-item">
                    <p>With a deposit of</p>
                  </div>
                  <div className="first-line-item">
                    <div className="text_control dollor-sign">
                      <label data-domain="$">
                        <input
                          name="Borrow_Deposit"
                          autocomplete={false}
                          value={formValues["Borrow_Deposit"]}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={`income__input ${
                            errorFields["Borrow_Deposit"] ? `error` : ``
                          }`}
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="check__wrapper baloon-check">
                <div className={`check ${isResidualVisible ? `` : `hide`}`}>
                  {/* <input
                    type="checkbox"
                    onChange={onChangeInput}
                    checked={residualOrBaloonPayment}
                  />
                  <div className={`check-text font ${``}`}>
                    Do you want a Residual or balloon payment
                  </div> */}
                  <div className="checkbox-item">
                    <input
                      id="checkbox-3"
                      class="checkbox-custom"
                      name="Baloon_Residual_Payment"
                      type="checkbox"
                      onChange={onChangeInput}
                      checked={residualOrBaloonPayment}
                    />
                    <label for="checkbox-3" class="checkbox-custom-label">
                      {" "}
                      Do you want a Residual or balloon payment
                    </label>
                  </div>
                </div>
                <div className="baloon__payment__section">
                  {residualOrBaloonPayment ? (
                    <>
                      <div className="fifth-line flex-row">
                        <div className="fourth">
                          <div className="font">With a residual of </div>
                        </div>
                        <div className="residual__input percentage-sign">
                          <input
                            autoComplete="off"
                            className={`income__input ${
                              errorFields["Borrow_Baloon_Percent"]
                                ? `error`
                                : ``
                            }`}
                            name="Borrow_Baloon_Percent"
                            value={formValues["Borrow_Baloon_Percent"]}
                            onChange={handleInputChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div className="residual__input dollor-sign">
                          <input
                            autoComplete="off"
                            className={`income__input ${
                              errorFields["Borrow_Baloon_Amount"] ? `error` : ``
                            }`}
                            name="Borrow_Baloon_Amount"
                            value={formValues["Borrow_Baloon_Amount"]}
                            onChange={handleInputChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="checkbox-wrapper">
                  <div className="flex-col">
                    <div className="checkbox-item">
                      <input
                        id="checkbox-1"
                        class="checkbox-custom"
                        checked={keepRebate}
                        name="Keep_Rebate_Or_Delayed_Deposit"
                        type="radio"
                        onClick={(e) => {
                          setKeepRebate(!keepRebate);
                          setFormValues({
                            ...formValues,
                            [e.target.name]: `Keep Rebate`,
                          });
                        }}
                      />
                      <label for="checkbox-1" class="checkbox-custom-label">
                        Do you want to keep the rebate
                      </label>
                    </div>
                    <div className="checkbox-item">
                      <input
                        id="checkbox-2"
                        class="checkbox-custom"
                        name="Keep_Rebate_Or_Delayed_Deposit"
                        type="radio"
                        onClick={(e) => {
                          setKeepRebate(!keepRebate);
                          setFormValues({
                            ...formValues,
                            [e.target.name]: `Delayed Deposit`,
                          });
                        }}
                        checked={!keepRebate}
                      />
                      <label for="checkbox-2" class="checkbox-custom-label">
                        Use it as a delayed deposit for your loan
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="check top">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setKeepRebate(!keepRebate);
                    }}
                    checked={keepRebate}
                  />{" "}
                  <div className="check-text font">
                    do you want to keep the rebate{" "}
                  </div>
                </div> */}
                {/* <div className="check">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setDelayedDeposit(!delayedDeposit);
                    }}
                    checked={delayedDeposit}
                  />
                  <div className="check-text font">
                    Use it as a delayed deposit for your loan
                  </div>
                </div>{" "} */}
              </div>
              <div className="sixth">
                <button
                  onClick={handleSubmit}
                  className="button primaryBackground apply"
                >
                  Apply now
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default Landing;
