import { buyNowPayLaterFields, carLoanFields, creditCardsNoField, expenceFields, mortageFields, personalLoanFields } from "./constants";

export const fetchLabelText = (field,creditCardFields=[]) => {
  switch (field) {
    case expenceFields[0]:
      return "Housing ";
    case expenceFields[1]:
      return "Insurance ";
    case expenceFields[2]:
      return "Utilites  ";
    case expenceFields[3]:
      return "Other  ";
    case expenceFields[4]:
      return "Personal ";
    case expenceFields[5]:
      return "Living Expenses ";
    case mortageFields[0]:
      return "Mortgage Provider ";
    case mortageFields[1]:
      return "Mortgage Balance ";
    case creditCardsNoField[0]:
      return "How many credit cards do you have? ";
    case creditCardFields[0]:
      return "Credit Card Provider 1";
    case creditCardFields[1]:
      return "Credit Card Limit 1";
    case creditCardFields[2]:
      return "Credit Card Provider 2";
    case creditCardFields[3]:
      return "Credit Card Limit 2";
    case creditCardFields[4]:
      return "Credit Card Provider 3";
    case creditCardFields[5]:
      return "Credit Card Limit 3";
    case creditCardFields[6]:
      return "Credit Card Provider 4";
    case creditCardFields[7]:
      return "Credit Card Limit 4";
    case creditCardFields[8]:
      return "Credit Card Provider 5";
    case creditCardFields[9]:
      return "Credit Card Limit 5";
    case buyNowPayLaterFields[0]:
      return "Provider :";
    case buyNowPayLaterFields[1]:
      return "How much is owing :";
    case personalLoanFields[0]:
      return "Personal loan provider :";
    case personalLoanFields[1]:
      return "Personal loan balance :";
    case personalLoanFields[2]:
      return "Personal loan repayment amount :";
    case carLoanFields[0]:
      return "Car loan provider :";
    case carLoanFields[1]:
      return "Car loan balance :";
    case carLoanFields[2]:
      return "Car loan repayment amount :";
    default:
      break;
  }
};
