import React, { useContext, useEffect, useState } from "react";
import  { useNavigate } from 'react-router-dom';
import "./index.css";
import Card from "../../common/components/other/card";
import Person from "../../common/assets/images/person.png";
import Business from "../../common/assets/images/business.png";
import { AppContext } from "../../app_provider";

const ApplicationType = () => {
  const {payloadToSend,updatePayload,updateLoading} = useContext(AppContext);
    const history = useNavigate();
  const [applicationType, setApplicationtype] = useState();
  useEffect(()=>{
    updateLoading(false);
  },[]);
  const handleClick=()=>{
    history("/prerequisties");
  };
  return (
    <>
      <div className="application-root">
        <Card>
          <div className="header">
            <strong>How would you like to apply</strong>
          </div>
          <div className=" ">
            <div className="selection-panel">
              <div className="blackBackground border-radius-small">
                <div
                  className={`panel-item ${
                    applicationType === "Person" ? `primaryBackground` : ``
                  }`}
                  onClick={() => {
                    setApplicationtype("Person");
                    handleClick();
                  }}
                >
                  <div className="primaryBackground panel-item-inner">
                    <img src={Person} alt="Person Icon"/>
                  </div>
                  <div className="panel-inner-item-type">Individual</div>
                  <div className="panel-inner-item-type"><strong>&#8594;</strong></div>
                </div>
              </div>
              <div className="blackBackground border-radius-small">
                <div
                  className={`panel-item ${
                    applicationType === "Business" ? `primaryBackground` : ``
                  }`}
                  onClick={() => {
                    setApplicationtype("Business");
                    handleClick();
                  }}
                >
                  <div className="primaryBackground panel-item-inner">
                    <img src={Business} alt="Business Icon"/>
                  </div>{" "}
                  <div className="panel-inner-item-type">Business</div>
                  <div className="panel-inner-item-type"><strong>&#8594;</strong></div>
                </div>
                </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default ApplicationType;
