import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../app_provider";
import {
  fetchDealFieldsFromApp,
  downloadFieldsPipedrive,
  saveDealFieldsToApp,
  storeResidenceYears,
  storeFirstName,
  readResidenceYears,
  storeDealFormFields,
  fetchDealFormFieldsFromApp,
  extractAPIKey,
  storeMarriageStatus,
  storeMortageStatus,
  storePRStatus,
} from "../../utilities/methods";
import ButtonPanel from "../../common/components/other/button_panel";
import "./personal_info_joint.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  checkCreditScore,
  fetchScoreFromApi,
  placeLookup,
  saveJointPersonalInfo,
  updateADeal,
} from "../../utilities/api";
import AutoCompleteInput from "../../common/components/suggestion/suggestion";
import {
  createAddressFormJson,
  createCompleteFieldsList,
  createPersonalFormJson,
  createPreviousAddressFormJson,
  createResidenceFormJson,
  downloadDealFields,
  getApiKeyOfCreditScore,
  getApiKeysOfCreditCheckFields,
  renderOptions,
  storeFieldsKeys,
} from "./methods";
import {
  addressPreviousInfo,
  nzResident,
  validationExcludeList,
} from "./const";
const JointApplicantPersonalInformation = () => {
  const navigate = useNavigate();
  const { updateLoading, isLoading, setProgress, openAlert, setIsMarried } =
    useContext(AppContext);
  const [personalInformation, setPersonalInfo] = useState([]);
  const [dealFields, setFields] = useState([]);
  const [addressInformation, setAddressInfo] = useState([]);
  const [previousAddressInformation, setPreviousAddressInfo] = useState([]);
  const [residentDuration, setResidentDuration] = useState([]);
  const [retryCount, setRetryCount] = useState();
  const [gender, setgender] = useState("male");
  const [previousVisible, setPreviosVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [suggestionList, setSuggestionList] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [searchParams, seSearchParams] = useSearchParams();
  const { dealId } = useParams();

  useEffect(() => {
    setProgress(46);
    preLoadingActions();
    if(dealId){
      
    }
  }, []);
  const preLoadingActions = async () => {
    updateLoading(true);

    await downloadDealFields(updateLoading, retryCount, setRetryCount);
    let fields = fetchDealFieldsFromApp();
    setFields(fields);
    let formConstructorPersonalInfo = createPersonalFormJson(fields);
    let formConstructorAddressInfo = createAddressFormJson(fields);
    let formConstructorPreviousAddressInfo =
      createPreviousAddressFormJson(fields);
    let formResidenceDuration = createResidenceFormJson(fields);

    let sortedpersonalInfoFields = formConstructorPersonalInfo.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    let sortedAddressInfoFields = formConstructorAddressInfo.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    let sortedPreviousAddressInfoFields =
      formConstructorPreviousAddressInfo.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
    let sortedResidentDurationFields = formResidenceDuration.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    storeDealFormFields(formConstructorPersonalInfo);
    storeDealFormFields(sortedAddressInfoFields);
    storeDealFormFields(sortedPreviousAddressInfoFields);
    storeDealFormFields(sortedResidentDurationFields);
    setPersonalInfo(formConstructorPersonalInfo);
    setAddressInfo(sortedAddressInfoFields);
    setPreviousAddressInfo(sortedPreviousAddressInfoFields);
    setResidentDuration(sortedResidentDurationFields);
    storeFieldsKeys(fields);
  };
useEffect(()=>{
  if(dealFields){
    updateLoading(false);
  }
},[dealFields]);
  useEffect(() => {
    if (dealFields&& formData) {
      let apiKeyMaritalStatus;
      apiKeyMaritalStatus = extractAPIKey("Joint_Applicant_Marital_Status");
      let apiKeyMortageStatus = extractAPIKey("Joint_Applicant_Resident_Type");
      storeMarriageStatus(formData[apiKeyMaritalStatus] === "married");
      storeMortageStatus(formData[apiKeyMortageStatus] === "mortgaged");
      console.log(formData[apiKeyMaritalStatus]);
      setIsMarried(formData[apiKeyMaritalStatus] === "married");
      let durationYearsKey = readResidenceYears();
      if (formData[durationYearsKey] < 3) {
        setPreviosVisible(true);
      } else {
        setPreviosVisible(false);
      }
    }
    return () => {};
  }, [formData, dealFields]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: undefined });
    setFormData({ ...formData, [name]: value });
  };
  const onSubmit = async () => {
    setFormErrors({});

    let fields = createCompleteFieldsList();
    let formError = {};
    fields.forEach((field) => {
      if (validationExcludeList.includes(field.name)) {
        return;
      }
      if (!previousVisible && addressPreviousInfo.includes(field.name)) {
        return;
      }
      if (
        formData[field.key] === null ||
        formData[field.key] === undefined ||
        formData[field.key] === ""
      ) {
        formError[field.key] = true;
      }
    });
    console.log(formError);
    if (Object.keys(formError).length > 0) {
      setFormErrors(formError);
      return;
    }
    updateLoading(true);
    try {
      let response =await saveJointPersonalInfo(formData,dealId);
      updateLoading(false);
      navigate(`/incomeAsset/${dealId}`);
    } catch (error) {
      console.log(error);
      updateLoading(false);
    }
   
  };
  const onChangeSuggestion = async (e) => {
    const { value } = e.target;
    try {
      let places = await placeLookup(value);
      console.log(places);
      setSuggestionList(places);
    } catch (error) {
      const { canSend } = error;
      if (canSend) {
        onChangeSuggestion(e);
      }
    }
  };
  const onSelect = (suggestion) => {
    console.log(suggestion);
    const { street_name, city, postal_code } = suggestion;
    let apiKeyStreetName = extractAPIKey("Joint_Applicant_Street_Name");
    let apiKeyCity = extractAPIKey("Joint_Applicant_City");
    let apiKeyPostalCode = extractAPIKey("Joint_Applicant_Postal_Code");
    let preparedCityForm = {
      [apiKeyStreetName]: street_name,
      [apiKeyPostalCode]: postal_code,
      [apiKeyCity]: city,
    };
    setFormData({ ...formData, ...preparedCityForm });
    setSelectedSuggestion(suggestion);
    console.log(preparedCityForm);
  };
  const onChangeRadio = (key, value, name) => {
    storePRStatus(value === "Yes" ? "true" : "false");
    setFormData({ ...formData, [key]: value });
  };

  return (
    <>
      <div className="main-wrapper">
        <form autoComplete="off">
          <div className="personal-info-wrapper">
            <div className="personal_info_title">PERSONAL INFORMATION</div>
            <div className="field-wrapper">
              {personalInformation.map((_field) => {
                return (
                  <div className="input-wrap">
                    <div>{_field.labelText}</div>
                    {_field.type === "dropdown" ? (
                      <select
                        name={_field.name}
                        onChange={onInputChange}
                        className={formErrors[_field.name] ? `error` : ``}
                      >
                        {renderOptions(_field).map((optionValue) => {
                          return (
                            <option value={optionValue.value}>
                              {optionValue.label}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        autoComplete="off"
                        key={_field.sortOrder}
                        type={_field.type}
                        placeholder={_field.hintText}
                        name={_field.name}
                        className={formErrors[_field.name] ? `error` : ``}
                        onChange={onInputChange}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="address__wrapper">
            <div className="title-wrapper">
              <div className="personal_info_title">ADDRESS INFORMATON</div>
            </div>
            <div className="field-wrapper">
              {/*addressInformation.map((_field) => {
                return (
                  <>
                    <div className="input-wrap">
                      <div>{_field.labelText}</div>
                      <input
                        autoComplete="off"
                        key={_field.sortOrder}
                        type="text"
                        placeholder={_field.hintText}
                        name={_field.name}
                        className={formErrors[_field.name] ? `error` : ``}
                        onChange={onInputChange}
                      />
                    </div>
                  </>
                );
              })*/}
              <div className="input-wrap ">
                <div>Address Lookup</div>
                <AutoCompleteInput
                  className="address_placeholder"
                  suggestions={suggestionList}
                  value={selectedSuggestion}
                  onChange={onChangeSuggestion}
                  onSelect={onSelect}
                />
              </div>
              <div className="input-wrap">
                <div>Time at Current Address</div>
                <div className="input-wrap-line">
                  {residentDuration.map((_field) => {
                    return (
                      <div className="dropdown_container">
                        {_field.type === "dropdown" ? (
                          <select
                            name={_field.name}
                            onChange={onInputChange}
                            className={formErrors[_field.name] ? `error` : ``}
                          >
                            {renderOptions(_field).map((optionValue) => {
                              return (
                                <option value={optionValue.value}>
                                  {optionValue.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            autoComplete="off"
                            key={_field.sortOrder}
                            type="text"
                            placeholder={_field.hintText}
                            name={_field.name}
                            className={formErrors[_field.name] ? `error` : ``}
                            onChange={onInputChange}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="nzpr__fields__wrap">
            {dealFields.map((entry) => {
              return nzResident.includes(entry.name) ? (
                <div className="expense__input__container radio__wrapper">
                  <div className="radio_label">
                    Are you a New Zealand Permanent resident
                  </div>
                  <div className="radio_select">
                    <div className="flex-row reduce_expense_radio">
                      <input
                        id="pays-radio"
                        className="checkbox-custom"
                        type={"radio"}
                        value="Yes"
                        onClick={() => {
                          onChangeRadio(entry.key, "Yes", entry.name);
                        }}
                        name={entry.key}
                      />
                      <label
                        for="pays-radio"
                        className="range__label checkbox-custom-label"
                      >
                        <div>Yes</div>
                      </label>
                    </div>
                    <div className="flex-row reduce_expense_radio">
                      <input
                        id="pays-radio-2"
                        type={"radio"}
                        value="No"
                        className="checkbox-custom"
                        onClick={() => {
                          onChangeRadio(entry.key, "No", entry.name);
                        }}
                        name={entry.key}
                      />
                      <label
                        for="pays-radio-2"
                        className="range__label checkbox-custom-label"
                      >
                        <div>No</div>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              );
            })}
          </div>
          <div
            className={`personal-info-wrapper ${previousVisible ? `` : `hide`}`}
          >
            <div className="title-wrapper">
              <div className="personal_info_title">
                PREVIOUS ADDRESS INFORMATON
              </div>
            </div>
            <div className="field-wrapper">
              {previousAddressInformation.map((_field) => {
                return _field.referenceName === "Expected_Amount" ? (
                  <></>
                ) : (
                  <div className="input-wrap">
                    <div>{_field.labelText}</div>
                    <input
                      autoComplete="off"
                      key={_field.sortOrder}
                      type="text"
                      className={formErrors[_field.name] ? `error` : ``}
                      placeholder={_field.hintText}
                      name={_field.name}
                      onChange={onInputChange}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </div>
      <ButtonPanel
        isPreviousEnabled={false}
        forwardMethod={async () => {
          console.log(`Clicked`);
          await onSubmit();
        }}
      />
    </>
  );
};
export default JointApplicantPersonalInformation;
