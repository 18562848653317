import { employementFields, expenceFields, incomeFields, liabilityFields, previousEmployementFields, valueFields } from "./const";

export const fetchLabelText = (field) => {
    switch (field) {
      case incomeFields[0]:
        return "What’s your monthly take home income,";
      case incomeFields[1]:
        return "Your Partner's income per month,";
      case incomeFields[2]:
        return "Other income per month,";
      case expenceFields[0]:
        return "Rates: ";
      case expenceFields[1]:
        return "Insurance: ";
      case expenceFields[2]:
        return "Utilites:  ";
      case expenceFields[3]:
        return "Dependents:  ";
      case expenceFields[4]:
        return "Mortgage/rent: ";
      case expenceFields[5]:
        return "Living Expenses: ";
      case liabilityFields[0]:
        return "Mortgage/rent:";
      case liabilityFields[1]:
        return "Total owing:";
      case liabilityFields[2]:
        return "Credit Cards limits:";
      case employementFields[0]:
        return "Current Employer:";
      case employementFields[1]:
        return "Job Description:";
      case employementFields[2]:
        return "Employment  Status:";
      case employementFields[3]:
        return "Time With Employer:";
      case valueFields[0]:
        return "Do you own a home:";
      case valueFields[1]:
        return "Investment property:";
      case valueFields[2]:
        return "Vehicles :";
      case valueFields[3]:
        return "Contents :";
      case previousEmployementFields[0]:
        return "Previous Employer:";
      case previousEmployementFields[1]:
        return "Previous Description:";
      case previousEmployementFields[2]:
        return "Previous Employment  Status:";
      default:
      
    }
  };
  export const renderOptions = (field) => {
    switch (field.name) {
      case "Current_Employment_Status":
        return [
          {
            value: undefined,
            label: field.name.split("_").join(" "),
          },
          {
            value: "full-time",
            label: "Full Time",
          },
          {
            value: "part-time",
            label: "Part Time",
          },
          {
            value: "casual",
            label: "Casual",
          },
          {
            value: "contract",
            label: "Contract",
          },
        ];
      
        case "Previous_Employment_Status":
          return [
            {
              value: undefined,
              label: field.name.split("_").join(" "),
            },
            {
              value: "full-time",
              label: "Full Time",
            },
            {
              value: "part-time",
              label: "Part Time",
            },
            {
              value: "casual",
              label: "Casual",
            },
            {
              value: "contract",
              label: "Contract",
            },
          ];
        
      case "Time_With_Employer_Years":
        return [
          {
            value: null,
            label: "Years",
          },
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "9",
            label: "9",
          },
          {
            value: "10",
            label: "10",
          },
          {
            value: "10+",
            label: "10+",
          },
        ];
      
      case "Time_With_Employer_Months":
        return [
          {
            value: null,
            label: "Months",
          },
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "9",
            label: "9",
          },
          {
            value: "10",
            label: "10",
          },
          {
            value: "11",
            label: "11",
          },
          {
            value: "12",
            label: "12",
          },
        ];
      
        case "Time_With_Previous_Employer_Years":
          return [
            {
              value: null,
              label: "Years",
            },
            {
              value: "0",
              label: "0",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
            {
              value: "6",
              label: "6",
            },
            {
              value: "7",
              label: "7",
            },
            {
              value: "8",
              label: "8",
            },
            {
              value: "9",
              label: "9",
            },
            {
              value: "10",
              label: "10",
            },
            {
              value: "10+",
              label: "10+",
            },
          ];
        
        case "Time_With_Previous_Employer_Months":
          return [
            {
              value: null,
              label: "Months",
            },
            {
              value: "0",
              label: "0",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
            {
              value: "6",
              label: "6",
            },
            {
              value: "7",
              label: "7",
            },
            {
              value: "8",
              label: "8",
            },
            {
              value: "9",
              label: "9",
            },
            {
              value: "10",
              label: "10",
            },
            {
              value: "11",
              label: "11",
            },
            {
              value: "12",
              label: "12",
            },
          ];
        
      default:
      
    }
  };